html, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: #6c757d !important;*/
  height: 100%;
  background-color: #f2f2f2 !important;
}

.container {
	min-height: 100%;
	overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.signUpButton {
  margin-top: 5px !important;
}

.signUpText {
  margin-bottom: 10px !important;
}


.cardHeader {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.cardSubheader {
  color: #585D5F;
  text-align: center;
}

.cardRightJustify {
  text-align: right;
}

.cardCenterJustify {
  text-align: center;
}

.weeklyCurrencies {
  color: #008F6D;
  font-weight: bold;
  font-size: 18pt;
  font-family: 'Open Sans', sans-serif;
}

.monthlyCurrencies {
  color: #1E4E77;
  font-weight: bold;
  font-size: 18pt;
}

.drawingDate {
  padding-top: 24px  !important;
  padding-bottom: 24px  !important;
  color: #585D5F;
}

.quote {
  color: #585D5F;
  text-align: right;
}

.accountItem{
  padding: 0 15px !important;
}

.dashboardContainer {
  margin-top: 20px;
}

.MuiAlert-filledSuccess {
  background-color: #008F6D !important;
  font-size: 18px  !important;
}

a {
  color: #F37748 !important;
}

.MuiTableCell-head {
  color: #1e4e77 !important;
}

.overflow-auto {
  overflow: auto;
}